<template>
    <div id="credit-memos-list">
        <b-overlay :show="show" spinner-variant="primary">
            <b-card>

                <ag-grid :withFilters="true" :filterComponent="AgGridDateFilter" :agGrid="agGrid"
                    pageTitle="Liste des avoirs" @refresh="loadData" @filterClicked="filterClicked" />

            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue"
import AgGridDateFilter from '@/views/customer/docs/AgGridDateFilterCurrYear'
import { formatPrice } from "@/utils/formatting";

export default {
    components: {
        AgGrid
    },
    data() {
        return {
            AgGridDateFilter: AgGridDateFilter,
            startDate: new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            agGrid: {
                columnDefs: [
                    {
                        headerName: "N° Avoir.R",
                        field: "No",
                        width: 200
                    },
                    // {
                    //     headerName: "N° Avoir",
                    //     field: "Posting_No",
                    //     width: 200
                    // },
                    {
                        headerName: "Date",
                        field: "Posting_Date",
                        valueGetter: function (tr) {
                            if (tr.data.Posting_Date != null)
                                return tr.data.Posting_Date.substring(0, 10);
                            return ''
                        },
                        //cellClass: 'right-customeCellRender',
                        //headerClass: 'right-customeHeaderRender',
                        width: 200,
                    },
                    // {
                    //     headerName: "Description",
                    //     field: "Posting_Description",
                    //     width: 200
                    // },
                    {
                        headerName: "Montant H.T",
                        field: "Amount",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Amount);
                        },
                        //cellClass: 'right-customeCellRender',
                        //headerClass: 'right-customeHeaderRender',
                        width: 200
                    },
                    {
                        headerName: "Montant T.T.C",
                        field: "Amount_Including_VAT",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Amount);
                        },
                        //cellClass: 'right-customeCellRender',
                        //headerClass: 'right-customeHeaderRender',
                        width: 200
                    },
                    // {
                    //     headerName: "Statut",
                    //     field: "Status",
                    //     width: 200
                    // },
                    {
                        headerName: "Type",
                        field: "Gen_Prod_Posting_Group",
                        cellRendererFramework: 'badgeCellRenderer',
                        cellRendererParams: { componentName: "Shipments", columnName: "Gen_Prod_Posting_Group" },
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.printDoc, icon: "picture_as_pdf", tooltip: "Imprimer" },
                        width: 100
                    },

                ],
                rows: []
            },
            commandesSelected: [],
            show: true,
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.loadData();
    },
    methods: {
        filterClicked(obj) {
            this.startDate = obj.sDate;
            this.endDate = obj.eDate;
            this.loadData();
        },
        async printDoc(event) {
            this.show = true;
            let response = "";
            if (event.No.startsWith('AVT')) {
                await this.$http.put("credit_memo_header", { Print: true }, { params: { No: "'" + event.No + "'", "Document_Type": "Credit Memo" } }).catch(() => this.show = false);
                response = await this.$http.get("file/document/" + event.Posting_No.replace(/:/g, "") + ".pdf?type=creditMemo", { responseType: "blob" }).catch(() => this.show = false);
            } else {
                await this.$http.put("validated_credit_memo_header", { Print: true }, { params: { No: "'" + event.No + "'" } }).catch(() => this.show = false);
                response = await this.$http.get("file/document/" + event.No.replace(/:/g, "") + ".pdf?type=validatedCreditMemo", { responseType: "blob" }).catch(() => this.show = false);
            }
            this.show = false;
            const fileURL = URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = fileURL;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
            URL.revokeObjectURL(fileURL);
        },
        async loadData() {
            this.show = true;
            let creditMemos = await this.$http.post("credit_memo_header/findWhere", this.generateDateFilters());
            let validatedCreditMemos = await this.$http.post("validated_credit_memo_header/findWhere", this.generateDateFilters());
            this.agGrid.rows = [...creditMemos.data, ...validatedCreditMemos.data];
            this.agGrid.rows.sort((a, b) => new Date(b.Posting_Date) - new Date(a.Posting_Date));
            this.show = false;
        },
        generateDateFilters() {
            const filters = [
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);
            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];
            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate;
            filters[1].value = formattedEndDate;
            return filters;
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>